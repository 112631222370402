import React, { useEffect, useState } from "react";
import SearchInput from "../../Components/SearchInput";
import SearchableSelect from "../../Components/SearchableSelect";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import DatePicker from "../../Components/DatePicker/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import PaginationBar from "../../Components/PaginationBar";
import PopUpModal from "../../Components/Modal";
import AddonsRequestModal from "../../Components/Addons/AddonsRequestModal";
import DisapproveRequestModal from "../../Components/Addons/DisapproveRequestModal";
import AddonsPaymentModal from "../../Components/Addons/AddonsPaymentModal";
import ApproveRequestModal from "../../Components/Addons/ApproveRequestModal";
const AddonsRequests = () => {
  let [addonsRequests, setAddonsRequests] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [refresh, toggle] = useState(false);
  const [orderBy, setOrderBy] = useState({ request_date: "DESC" });
  const [searchInput, setSearchInput] = useState("");
  const [status, setStatus] = useState("");
  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);
  const [itemsOptions, setItemsOptions] = useState([]);
  const [item, setItem] = useState("");
  const [error, setError] = useState("");
  const [requestId, setRequestId] = useState();
  const [isExtensionRequest, setIsExtensionRequest] = useState(false);
  const [disapprovedModalShow, setDisapprovedModalShow] = useState(false);
  const [approveModalShow, setApproveModalShow] = useState(false);
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [addRequestModalShow, setAddRequestModalShow] = useState(false);
  const fetchAllConsumableItemsCall = useApi(api.fetchAllConsumableItems);
  const fetchAllAddonsRequestsCall = useApi(api.fetchAllAddonsRequests);

  const fetchConsumableItemsOptions = async () => {
    try {
      const res = await fetchAllConsumableItemsCall.request();
      const data = res?.data?.data;
      if (data) {
        const resData = data?.consumableItems.map((item) => ({ label: item.name.replaceAll("_", " "), value: item.name }));
        setItemsOptions([{ value: "", label: "All" }, { value: "is-for-extension", label: "Plan Extension" }, ...resData]);
      }
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    fetchConsumableItemsOptions();
  }, []);
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };
  const resetFilterHandle = () => {
    setSearchInput("");
    setSizeOfPages(10);
    setItem("");
    setStatus("");
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const fetchAddonsRequests = async () => {
    try {
      const res = await fetchAllAddonsRequestsCall.request({
        size: sizeOfPages,
        page: currentPage,
        search: searchInput,
        consumableItem: item?.value,
        orderBy: orderBy,
        date: { from: fromDate, to: toDate },
        status: status.value,
      });
      const data = res?.data?.data;
      setIsLoading(false);
      if (data) {
        setAddonsRequests(
          data?.requests.map((item) => ({
            ...item,
            addons_type: item.is_for_extension === 1 ? "Plan Extension" : "Consumable Item",
          }))
        );
        setCount(data?.pagination?.no_of_items);
        if (data?.pagination?.no_of_pages === 0) {
          setNoOfPages(0);
        } else {
          setNoOfPages(data?.pagination?.no_of_pages);
          if (currentPage > data?.pagination?.no_of_pages) {
            setCurrentPage(data?.pagination?.no_of_pages);
          }
        }
      } else {
        setAddonsRequests([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAddonsRequests();
  }, [currentPage, sizeOfPages, searchInput, refresh, toDate, fromDate, item, status, orderBy]);

  useEffect(() => {
    if (currentPage === 1) {
      toggle((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);
  const handleStatus = (e) => {
    if (e.label === "All") {
      setStatus("");
    } else {
      setStatus({ value: e.value, label: e.label });
    }
  };
  const handleItemFilter = (e) => {
    if (e.label === "All") {
      setItem(null);
    } else {
      setItem({ value: e.value, label: e.label });
    }
  };
  const closeModalAdd = () => {
    setAddRequestModalShow(false);
  };

  const disapproved = async (data) => {
    setRequestId(data?.id);
    setDisapprovedModalShow(true);
  };
  // const payment = async (data) => {
  //   setRequestId(data);
  //   setPaymentModalShow(true);
  // };
  const [caretData, setCaretData] = useState();
  const caret = async (id) => {
    const data = addonsRequests.filter((item) => item.id === id);
    if (data[0].is_for_extension === 1) {
      const value = {
        requested_duration: data[0].values.requested_duration,
        current_end_date: data[0].values.current_end_date.split("T")[0].split("-").reverse().join("-"),
        previous_end_date: data[0].values.previous_end_date.split("T")[0].split("-").reverse().join("-"),
        banner_showing_date: data[0].values.banner_showing_date.split("T")[0].split("-").reverse().join("-"),
        grace_period_end_date: data[0].values.grace_period_end_date.split("T")[0].split("-").reverse().join("-"),
      };
      setCaretData(value);
    } else {
      const value = Object.entries(data[0].values).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {});
      setCaretData(value);
    }
  };
  const approve = async (data) => {
    if (data.addons_type === "Plan Extension") {
      setRequestId(data?.id);
      setIsExtensionRequest(!!data?.is_for_extension);
      setApproveModalShow(true);
    } else {
      setRequestId(data?.id);
      setPaymentModalShow(true);
    }
  };
  const onApproveHide = () => {
    setApproveModalShow(false);
    setRequestId();
    setIsExtensionRequest(false);
  };
  return (
    <div>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder="Filter Status"
              value={status}
              searchable={false}
              options={[
                { value: "", label: "All" },
                { value: "Pending", label: "Pending" },
                { value: "Done", label: "Done" },
                { value: "Cancelled", label: "Cancelled" },
              ]}
              handleChange={handleStatus}
            />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect selectClassName={"bg-white"} placeholder="Filter Request" value={item} searchable={false} options={itemsOptions} handleChange={handleItemFilter} />
          </Col>
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
          <Col xs="2" lg="2">
            <Button className="h-100 w-100" style={{ fontSize: "0.75rem" }} size="sm" type="submit" onClick={() => setAddRequestModalShow(true)}>
              <span className="d-none d-lg-inline">Add Request</span> <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Col>
        </Row>
        <>
          <div className="pt-2">
            <Button className="mt-3" size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <img src={customloader} alt="customloader" width="100" height="100" />
            </div>
          ) : addonsRequests.length === 0 ? (
            <NoResult name="Addons Request" />
          ) : (
            <Row style={{ marginBottom: "-3rem" }} className="mt-3">
              <CustomizeTableview data={addonsRequests} columnNotShow={["id", "values", "is_paid", "is_for_extension", "transaction"]} sortColumn={["shop_name"]} orderBy={orderBy} setOrderBy={setOrderBy} disapproved={disapproved} caret={caret} caretData={caretData} approve={approve} />
              <Row className="mt-5">
                <Col className="d-none d-lg-block" md="6" lg="3">
                  <span className="text-secondary fs-6">{count} result(s)</span>
                </Col>
                <Col xs="12" lg="6">
                  <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
                </Col>
                <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                  <span className="text-secondary fs-6">{count} Result(s)</span>
                </Col>
                <Col xs="6" lg="3" className="text-end mt-3"></Col>
              </Row>
            </Row>
          )}
        </>
        <PopUpModal size="lg" title="Add Addons Request" show={addRequestModalShow} onHide={() => setAddRequestModalShow(false)}>
          <AddonsRequestModal toggle={toggle} close={closeModalAdd} />
        </PopUpModal>
        <PopUpModal title="Disapprove Addons Request" show={disapprovedModalShow} onHide={() => setDisapprovedModalShow(false)}>
          <DisapproveRequestModal onClose={() => setDisapprovedModalShow(false)} requestId={requestId} toggle={toggle} />
        </PopUpModal>
        <PopUpModal title="Approve Extension Request" show={approveModalShow} onHide={() => setApproveModalShow(false)}>
          <ApproveRequestModal onClose={onApproveHide} requestId={requestId} isExtensionRequest={isExtensionRequest} toggle={toggle} />
        </PopUpModal>
        <PopUpModal title="Addons Payments" show={paymentModalShow} onHide={() => setPaymentModalShow(false)} size="lg">
          <AddonsPaymentModal onClose={() => setPaymentModalShow(false)} requestId={requestId} toggle={toggle} />
        </PopUpModal>
      </Container>
    </div>
  );
};

export default AddonsRequests;
